import React from 'react';
import triangle from '../assets/images/Triangle.svg'

export function EducationElement(props) {
    return (
        <div className='education_element' key={props.date + props.title}>
            <div className='education_element__left'>
                <p className='education_element__year'>{props.date}</p>
                <div className='education_element__vertical_stripe' />
            </div>
            <div className='education_element__triangle'>
                <img src={triangle} alt='Small triangular element that has to make the shape of the gray rectangle on the right a little nicer' />
            </div>
            <div className='education_element__container'>
                <p className='education_element__title'>{props.title}</p>
                <p>{props.text}</p>
            </div>
        </div>
    );
}
