import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MainContent } from '../components/MainContent';
import { Panel } from '../components/Panel';

export function MainPage(props) {
    return (
        <div className='main_page'>
            <Panel />
            <button className='burger' onClick={onClickBurger}><FontAwesomeIcon icon={faBars} /></button>
            <MainContent />
        </div>
    );
}

function onClickBurger() {
    document.querySelector('.panel').classList.toggle('panel--hidden');
}
