import { createServer } from "miragejs";

const defaultEducation = [ 
    {
        "date": 2013,
        "title": "Bauman Moscow State Technical University, Master of Aerospace Engineering, diploma with honors.",
        "text": "The program is devoted to spacecraft design. 3 years of advanced mathematics: Linear algebra, Theory of probability, Mathematical statistics; calculation methods applied to physics: Liquid and gas dynamics, Finite element calculations, Radiolocation. The final project was dedicated to developing a space mission to one of the Mars`s moons including orbit modelling, 3D design, liquid and solid body dynamics, and construction strength."
    },
    {
        "date": 2016,
        "title": "Moscow State Construction University, Bachelor of Civil Engineering.",
        "text": "Full time evening bachelor program for engineers working in construction that gives knowledge of local construction standards, architecture, methods of applied calculation, and specific project management approaches pertaining to construction site organization. Full set of project documentation for building a school was the final project."
    },
    {
        "date": 2018,
        "title": "Higher School of Economics, Bachelor of Management.",
        "text": "Full time evening program devoted to learning basics of economics, finance, corporate management, and marketing. As a final course project, the choice was to develop a program of industrializing a vehicle that has customizable decorative panels that can be easily changed with brand new sets portraying popular characters or released in collaboration with fashion designers and painters. The thesis project had been presented at the Department of Marketing and received excelent feedback from the Ford Motor Company."
    },
    {
        "date": 2019,
        "title": "Project Management Institute, PMP Certification.",
        "text": "Professional certification under the auspices of Project Management Institute, based on PMBoK standard."
    },
    {
        "date": 2019,
        "title": "Ford Academy, Six Sigma Green Belt Certification.",
        "text": "Professional certification under the auspices of Ford Motor Company, based on ISO 13053:2011."
    },
    {
        "date": 2020,
        "title": "Moscow Institute of Physics and Technology, Machine Learning and Data Analysis online course.",
        "text": "Online course dedicated to learning applied data analysis tools implemented in Python Notebook. Consists of 5 modules: Mathematics for data science, Supervised learning, Finding data patterns, Data science applications."
    },
    {
        "date": 2021,
        "title": "SkillFactory, DevOps online course.",
        "text": "Online course giving knowledge of main DevOps concepts and tools such as Jira/Trello, virtualization, orchestration, CI/CD, Docker/K8s, IaC, logging, monitoring."
    },
    {
        "date": 2022,
        "title": "EPAM, Frontend Development online course.",
        "text": "Online course giving advanced skills in web application development based on React, including hooks, Redux, React Router, and testing libraries."
    }
];

const defaultSkills = [
    {
        skill: 'Project Management',
        measure: 90,
    },
    {
        skill: 'Marketing',
        measure: 75,
    },
    {
        skill: 'Robotics',
        measure: 65,
    },
    {
        skill: 'Data Science',
        measure: 30,
    },
    {
        skill: 'DevOps',
        measure: 30,
    },
];

createServer({
    routes() {
        this.namespace = "api";
        this.timing = 3000;
        this.get("/educations", () => defaultEducation);
        this.get("/skills", () => defaultSkills);
    }
})