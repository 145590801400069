import React from 'react';

export function ExperienceElement(props) {
    return (
        <div className='experience_element' key={props.date + props.info.company + props.info.job}>
            <div className='experience_element__left'>
                <p className='experience_element__company'>{props.info.company}</p>
                <div className='experience_element__year'>
                    {props.date}
                </div>
            </div>
            <div className='experience_element__container'>
                <p className='experience_element__job'>{props.info.job}</p>
                <p className='experience_element__description'>{props.info.description}</p>
            </div>
        </div>
    );
}
