import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSkill } from '../store/actions';
import { thunkFetchSkills } from '../store/thunk';
import { SkillsElement } from './SkillsElement';
import { SkillsForm } from './SkillsForm';

export const Skills = () => {
    const [editHidden, setEditHidden] = useState(true);
    let content = useSelector((state) => state.skills);
    const dispatch = useDispatch();
    function onClick() {
        document.querySelector('.skills__form').classList.toggle('hidden');
        setEditHidden(!editHidden);
    };
    useEffect(() => {
        if (!content.length) {
            dispatch(thunkFetchSkills()).unwrap().then((data) => data.forEach((skill) => dispatch(addSkill(skill))));
        };
        // eslint-disable-next-line
    }, []);
    return (
        <div className='skills'>
            <div className='skills__header'>
                <h2 className='skills__title'>Skills</h2>
                <button className='skills__button--edit' onClick={onClick}><FontAwesomeIcon icon={faEdit} /> {editHidden? 'Open' : 'Close'} edit</button>
            </div>
            <SkillsForm />
            <div className='skills__diagram'>
                {content.map((entry) => SkillsElement(entry))}
            </div>
            <div className='skills__scale' />
        </div>
    );
};
