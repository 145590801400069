import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {Navigation} from './Navigation';
import { PhotoBox } from './PhotoBox';

export function Panel(props) {
    return (
        <div className='panel'>
            <PhotoBox />
            <Navigation />
            <Link to='/'>
                <button className='menu__button--back'>
                    <FontAwesomeIcon className='menu_back__pictogram' icon={faAngleLeft} />
                    <p className='menu_back__text'>Go back</p>
                </button>
            </Link>
        </div>
    );
}
