import React from 'react';
import avatar from '../assets/images/1555491586129.jfif';

export function PhotoBox() {
    return (
        <div className='menu__photobox'>
            <img className='menu__avatar' src={avatar} alt='Avatar' />
            <h3 className='menu__header'>Ivan Teterin</h3>            
        </div>
    );
}
