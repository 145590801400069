export const ADD_SKILL = 'ADD_SKILL';
export const addSkill = (obj) => ({type: ADD_SKILL, payload: obj});

export const ADD_EDUCATION = 'ADD_EDUCATION';
export const addEducation = (obj) => ({type: ADD_EDUCATION, payload: obj});

export const FETCH_EDUCATION = 'FETCH_EDUCATION';
export const fetchEducation = () => ({type: FETCH_EDUCATION});

export const FETCH_SKILLS = 'FETCH_SKILLS';
export const fetchSkills = () => ({type: FETCH_SKILLS});
