import { createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_EDUCATION, FETCH_SKILLS } from "./actions";

export const thunkFetchEducation = createAsyncThunk(
    FETCH_EDUCATION,
    async () => {
        const response = await fetch('/api/educations');
        const data = await response.json();
        return data;
    }
);

export const thunkFetchSkills = createAsyncThunk(
    FETCH_SKILLS,
    async () => {
        const response = await fetch('/api/skills');
        const data = await response.json();
        return data;
    }
);
