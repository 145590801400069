import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { thunkFetchEducation } from '../store/thunk';
import { EducationElement } from './EducationElement';

export const Education = () => {
    const LoadingEducationScreen = () => {
        return (
            <div className='education--loading'>
                <FontAwesomeIcon className='loading_emblem' icon={faArrowsRotate} />
            </div>
        );
    };    
    const FailEducationScreen = () => {
        return (
            <div className='education--fail'>
                <p>Something went wrong; please review your server connection</p>
            </div>
        );
    };    
    const SuccessEducationScreen = (content) => {
        return (
            <div className='education__timeline'>
                {content.map((entry) => EducationElement(entry))}
            </div>
        );
    };
    const [section, setSection] = useState(LoadingEducationScreen());
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunkFetchEducation()).unwrap().then((data) => {
            setSection(SuccessEducationScreen(data));
        })
        .catch(() => setSection(FailEducationScreen()));
        // eslint-disable-next-line
    }, []);
    return (
        <div className='education'>
            <h2 className='box__title'>Education</h2>
            {section}
        </div>
    );
};


