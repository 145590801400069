import React from 'react';

export const Box = () => {
    return (
        <div className='box'>
            <h2 className='box__title'>About me</h2>
            <p className='box__text'>Project manager with 5 years of experience in production launch and construction
             within the automotive and other manufacturing industries and over 1 year of experience in
              IT / robotics project management. Certified Project Management Professional.</p>
            <ul className='box__list'>
                <li className='box__list-item'>Successfully completed over 40 projects with a total
                 budget of more than $50 million.</li>
                <li className='box__list-item'>Managed projects within waterfall and agile lifecycles.</li>
                <li className='box__list-item'>Completed over 150 change requests.</li>
                <li className='box__list-item'>Coordinated the completion of over 10 8D problem solving reports.</li>
            </ul>
        </div>
    );
};
