import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faComment, faGem, faGraduationCap, faLocationArrow, faPen, faUserLarge } from '@fortawesome/free-solid-svg-icons'

export function Navigation() {
    const [aboutActive, setAboutActive] = useState(true);
    const [educationActive, setEducationActive] = useState(false);
    const [experienceActive, setExperienceActive] = useState(false);
    const [skillsActive, setSkillsActive] = useState(false);
    const [portfolioActive, setPortfolioActive] = useState(false);
    const [contactsActive, setContactsActive] = useState(false);
    const [feedbacksActive, setFeedbacksActive] = useState(false);
    function goToAbout() {
        window.scrollTo({top: document.querySelector('.main_content').offsetTop, behavior: 'smooth'})
    };
    function goToEducation() {
        window.scrollTo({top: document.querySelector('.education').offsetTop, behavior: 'smooth'})
    };
    function goToExperience() {
        window.scrollTo({top: document.querySelector('.experience').offsetTop, behavior: 'smooth'})
    };
    function goToSkills() {
        window.scrollTo({top: document.querySelector('.skills').offsetTop, behavior: 'smooth'})
    };
    function goToPortfolio() {
        window.scrollTo({top: document.querySelector('.portfolio').offsetTop, behavior: 'smooth'})
    };
    function goToContacts() {
        window.scrollTo({top: document.querySelector('.contacts').offsetTop, behavior: 'smooth'})
    };
    function goToFeedbacks() {
        window.scrollTo({top: document.querySelector('.feedbacks').offsetTop, behavior: 'smooth'})
    };
    function setActiveLinks() {
        if (window.scrollY < 10) {
            setAboutActive(true);
            setEducationActive(false);
        }
        else if (window.scrollY <= document.querySelector('.education').offsetTop + 10) {
            setAboutActive(false);
            setEducationActive(true);
            setExperienceActive(false);
        }
        else if (window.scrollY <= document.querySelector('.experience').offsetTop + 10) {
            setEducationActive(false);
            setExperienceActive(true);
            setSkillsActive(false);
        }
        else if (window.scrollY <= document.querySelector('.skills').offsetTop + 10) {
            setExperienceActive(false);
            setSkillsActive(true);
            setPortfolioActive(false);
        }
        else if (window.scrollY <= document.querySelector('.portfolio').offsetTop + 10) {
            setSkillsActive(false);
            setPortfolioActive(true);
            setContactsActive(false);
        }
        else if (window.scrollY <= document.querySelector('.contacts').offsetTop + 10) {
            setPortfolioActive(false);
            setContactsActive(true);
            setFeedbacksActive(false);
        }
        else if (window.scrollY <= document.querySelector('.feedbacks').offsetTop + 10) {
            setContactsActive(false);
            setFeedbacksActive(true);
        }
    };
    window.addEventListener('scroll', setActiveLinks);
    return (
        <div>
            <ul className='menu__list'>
                <li className='menu__item'>
                    <div className={'menu__link' + (aboutActive? ' active' : '')} onClick={goToAbout}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faUserLarge} />
                        <p className='menu__item--label'>About me</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (educationActive? ' active' : '')} onClick={goToEducation}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faGraduationCap} />
                        <p className='menu__item--label'>Education</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (experienceActive? ' active' : '')} onClick={goToExperience}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faPen} />
                        <p className='menu__item--label'>Experience</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (skillsActive? ' active' : '')} onClick={goToSkills}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faGem} />
                        <p className='menu__item--label'>Skills</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (portfolioActive? ' active' : '')} onClick={goToPortfolio}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faBriefcase} />
                        <p className='menu__item--label'>Portfolio</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (contactsActive? ' active' : '')} onClick={goToContacts}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faLocationArrow} />
                        <p className='menu__item--label'>Contacts</p>
                    </div>
                </li>
                <li className='menu__item'>
                    <div className={'menu__link' + (feedbacksActive? ' active' : '')} onClick={goToFeedbacks}>
                        <FontAwesomeIcon className='menu__pictogram' icon={faComment} />
                        <p className='menu__item--label'>Feedbacks</p>
                    </div>
                </li>
            </ul>
        </div>
    );
}
