import { ADD_EDUCATION, ADD_SKILL } from "./actions";

function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const initialState = loadFromLocalStorage() || { skills: [], education: []};

export function skills(state = initialState.skills, action) {
    const type = action.type;
    switch (type) {
        case ADD_SKILL: {
            return [...state, action.payload];
        }
        default: return state;
    }
}

export function education(state = initialState.education, action) {
    const type = action.type;
    switch (type) {
        case ADD_EDUCATION: {
            return [action.payload];
        }
        default: return state;
    }
}
