import React from 'react';
import { ExperienceElement } from './ExperienceElement';

const defaultContent = [
    {
        date: '2021 - now',
        info: {
            company: 'Interlake Mecalux',
            job: 'Project manager (turnkey automated warehouse solutions)',
            description: 'Manage automated warehouse construction projects from contract signature to final commission. 4 projects at the same time with total budget $15 million. Act as a liaison between the customer contacts on the project site and Interlake Mecalux departments to coordinate the delivery and installation of automation products, materials and associated labor. Determine and obtain necessary permitting required for the installation. Coordinate the work of electromechanics, controls, and software engineers and installers. Write project reports, memoranda, and correspondence detailing project status and issues. Inspect subcontracted installation work of Interlake Mecalux products to ensure compliance with contractual commitments, safety and labor regulations, and environmental regulations. Negotiate with the customer to determine the cost, terms, and conditions of change orders. Supervise the installation of Interlake Mecalux products as well as purchased products and materials. Resolve and determine alternative solutions to construction, manufacturing, installation, or equipment issues and problems at customer sites.'
        }
    },
    {
        date: '2019 - 2020',
        info: {
            company: 'Schneider Electric',
            job: 'Project Manager (wired devices and final distribution equipment)',
            description: 'Led wired devices, final distribution equipment and connected smart home devices production launch projects at local corporate production sites. Managed IT projects related to smart home embedded software connected to AWS and corresponding web and mobile applications. Prepared gateway presentations representing data with Power BI. Set project baselines in MS Project using waterfall and agile approaches and communicated them in Box folders. Accountable for $ 3.5 million project budget consisting of marketing research, product development, validation and go-to-market activities. Established the development plan for over 150 plastic molding tool sets produced or currently in production. Managed cross-functional international project teams consisting of over 20 people. Implemented global quality procedures in transitioning a wired devices production plant, acquired by Schneider, to Schneider processes. Collaborated with quality assurance teams to change procedures for the creation of failure modes and effect analysis (both DFMEA and PFMEA) and control plans included as deliverables of every project and training program scheduled during the production plants transition. Introduced engineering change management implemented as a procedure. As a result, all projects were completed within their baselines. Measured project success using Excel + VBA and Oracle.'
        }
    },
    {
        date: '2017 - 2019',
        info: {
            company: 'Ford Motor Company',
            job: 'Localization Engineer',
            description: 'Managed IP / air ducts / hard trims vehicle components production launch projects as a design and release engineer at build-to-print suppliers` sites in line with GPDS program timing plans and IATF 16949 with support from supplier technical assistance. Ford Transit, EcoSport, Kuga successfully released into market. Developed and proposed part modifications completed in CATIA / Teamcenter VisMockup. Responsible for project quality, costs, and schedule. All the GPDS project milestones have been passed in time with 100% PPAP deliverables validation. Took part in AIMS resolution with new model launch, quality engineers and manufacturing engineers. Coordinated G8D problem solving. Participated in vehicle engineering teams to ensure vehicle level validation tests success. Established regular meetings as a responsible for project communications. It enabled collaboration between multiple geographic locations (Mexico, United States, Brazil, Germany, Spain, India, Russia, China). Aligned commodity strategies with LVEA, finance and purchasing departments for future sourcing of localizable parts. Processed over 100 engineering changes, average completion time less than 7 days. Key member of business case preparation teams. Incorporated lessons learnt from previous programs into product development processes.'
        }
    },
    {
        date: '2016 - 2017',
        info: {
            company: 'Technoreserve LLC',
            job: 'Senior Project Manager',
            description: 'Led projects which are critical for company`s business (business processes reengineering, construction, new equipment development and installation, supporting biggest orders). Managed company`s operations (revenue in 2016 above $1mln, EBITDA above $100 000), accountable for overall performance. Coordinated continuous improvement process developed corporate standards for work instructions, FMEA, control plans, floor layouts, 5S. Managed a strain gauge weighing equipment installation project. Completed and negotiated commercial offers, approved contracts. Developed balanced scorecards according to corporate strategy to comply with ISO 9001 and translated them to KPIs. Successfully implemented supply chain software to eliminate corporate fraud and shutdowns due to lack of raw material by 100%. Established a training program for new sales representatives to increase customer satisfaction by 50%.'
        }
    },
    {
        date: '2014 - 2016',
        info: {
            company: 'Groupe Renault',
            job: 'Local Integration Leader',
            description: 'Managed interior trim (plastic and NVH) vehicle components production launch at build-to-print suppliers` sites in line with program timing plans. Renault Duster, Renault Kaptur, Nissan Terrano successfully released into market. Led teams consisting of manufacturing engineers, quality engineers, and assembly shop managers to perform test builds of parts modified in serial life. Collected more than 100 RFQ packs for interior/exterior vehicle components localization (part design, validation activities, critical characteristics) and aligning them with R&D teams and suppliers. Accountable for cost saving on components in serial life by means of implementing new raw materials and unification of parts. Member of Kaizen activities and AGV implementation at supplier sites. Developed a new supplier of chromium-plated plastic parts.'
        }
    },
    {
        date: '2009 - 2014',
        info: {
            company: 'Techno Plus LLC',
            job: 'Product Development Engineer',
            description: 'Led 3D development activities for a construction equipment manufacturer (concrete mixers, nonmetallic material classifiers) using SolidWorks. Developed 2D blueprints and GD&amp;T (geometric dimensioning and tolerancing) development using Autocad. Completed finite element calculation to ensure sufficient strength using MSC Nastran/Patran. Administered capture requirements for more than 30 new equipment projects. Controlled product quality of equipment during launch phases.'
        }
    }
];

export const Experience = () => {
    const content = defaultContent;
    return (
        <div className='experience'>
            <h2 className='box__title'>Experience</h2>
            <div className='experience__timeline'>
                {content.map((entry) => ExperienceElement(entry))}
            </div>
        </div>
    );
};
