import React from 'react';
import { Box } from './Box';
import { Education } from './Education';
import { Experience } from './Experience';
import { Skills } from './Skills';
import { Contacts } from './Contacts';
import { ScrollToTheTop } from './ScrollToTheTop';
import { Portfolio } from './Portfolio';
import { Feedbacks } from './Feedbacks';

export function MainContent(props) {
    return (
        <div className='main_content'>
            <Box />
            <Education />
            <Experience />
            <Skills />
            <Portfolio />
            <Contacts />
            <Feedbacks />
            <ScrollToTheTop />
        </div>
    );
}
