import React from 'react';
import photo1 from '../assets/images/liliya.jpg';
import photo2 from '../assets/images/1567754365625.jfif';
import { FeedbackElement } from './FeedbackElement';

const data = [
    {
        feedback: 'Vladimir, your feedback is appreciated.',
        reporter: {
            photoUrl: photo2,
            name: 'Vladimir Malygin',
            position: 'Software Developer',
            company: 'EPAM',
        }
    },
    {
        feedback: 'During his time on my team, Ivan impressed me with his performance as a team leader and with his involvement into the whole company`s business. He is always very organized and works hard. Ivan will be a great asset to your team.',
        reporter: {
            photoUrl: photo1,
            name: 'Liliya Garifullina',
            position: 'VP Home & Distribution',
            company: 'Schneider Electric',
        },
    }
];

export const Feedbacks = () => {
    return (
        <div className='feedbacks'>
            <h2 className='box__title'>Feedbacks</h2>
            <div className='feedback__container'>
                {data.map((entry) => FeedbackElement(entry))}
            </div>
        </div>
    );
};