import Isotope from 'isotope-layout';
import { useEffect, useRef, useState } from 'react';
import { PortfolioElement } from './PortfolioElement';
import wiser from '../assets/images/imgonline-com-ua-Resize-1CRx75if3tp.jpg';
import reactLogo from '../assets/images/imgonline-com-ua-Resize-74UxUIGx69Xs.jpg';
import python from '../assets/images/imgonline-com-ua-Resize-QSRHZxH0cj.jpg';
import mecalux from '../assets/images/imgonline-com-ua-Resize-uEzizvSLCY0uWVH.jpg';

const cards = [
    {
        url: reactLogo,
        title: 'This website...',
        text: 'It is nice, you like it, don`t you?',
    },
    {
        url: python,
        title: 'My python portfolio',
        text: 'It is on github, please let me know if you want to take a look.',
    },{
        url: wiser,
        title: 'Schneider Electric Wiser',
        text: 'Smart home offer from Schneider Electric. The project was managed from cradle to first serial samples, including mobile app and local cloud.',
    },{
        url: mecalux,
        title: 'Automated warehouse solutions',
        text: 'Two projects already delivered to customers within the US.',
    },
];

export function Portfolio() {
    const isotope = useRef();
    const menuAll = useRef();
    const menuCode = useRef();
    const menuUI = useRef();
    const [filterKey, setFilterKey] = useState("*");
    useEffect(() => {
        isotope.current = new Isotope(".filter-container", {
            itemSelector: ".filter-item",
            layoutMode: "fitRows"
        });
        return () => isotope.current?.destroy();
    }, []);
    useEffect(() => {
        menuAll.current?.classList.remove('portfolio__navigation_item--active');
        menuCode.current?.classList.remove('portfolio__navigation_item--active');
        menuUI.current?.classList.remove('portfolio__navigation_item--active');
        if (filterKey === "*") {
            isotope.current?.arrange({ filter: `*` });
            menuAll.current?.classList.add('portfolio__navigation_item--active');
        }
        else {
            isotope.current?.arrange({ filter: `.${filterKey}` });
            if (filterKey === 'code') menuCode.current?.classList.add('portfolio__navigation_item--active');
            else menuUI.current?.classList.add('portfolio__navigation_item--active');
        };
    }, [filterKey]);
    const handleFilterKeyChange = (key) => () => setFilterKey(key);
    return (
        <div className='portfolio'>
            <h2 className='box__title'>Portfolio</h2>
            <nav className='portfolio__navigation'>
                <p ref={menuAll} className='portfolio__navigation_item portfolio__navigation_item--active' onClick={handleFilterKeyChange("*")}>All </p>
                <p ref={menuCode} className='portfolio__navigation_item' onClick={handleFilterKeyChange("code")}>/ Code /</p>
                <p ref={menuUI} className='portfolio__navigation_item' onClick={handleFilterKeyChange("pm")}> Projects</p>
            </nav>
            <ul className="filter-container">
                <div className="filter-item code">
                    {PortfolioElement(cards[0])}
                </div>
                <div className="filter-item code">
                    {PortfolioElement(cards[1])}
                </div>
                <div className="filter-item pm">
                    {PortfolioElement(cards[2])}
                </div>
                <div className="filter-item pm">
                    {PortfolioElement(cards[3])}
                </div>
            </ul>
        </div>
    )        
};
