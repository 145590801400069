import { faEnvelope, faLink, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Contacts() {
    return (
        <div className='contacts'>
            <h2 className='box__title'>Contacts</h2>
            <ul className='contacts__list'>
                <li className='contacts__list_item'>
                    <FontAwesomeIcon className="contacts__icon" icon={faPhone} />
                    <div className="contacts__label">
                        <a href="tel:5708757352" className="contacts__link">570-875-7352</a>
                    </div>
                </li>
                <li className='contacts__list_item'>
                    <FontAwesomeIcon className="contacts__icon" icon={faEnvelope} />
                    <div className="contacts__label">
                        <a href="mailto:ivan.teterin.1990@gmail.com" className="contacts__link">ivan.teterin.1990@gmail.com</a>
                    </div>
                </li>
                <li className='contacts__list_item'>
                    <FontAwesomeIcon className="contacts__icon" icon={faLink} />
                    <div className="contacts__label">
                        <p className="contacts__network_name">Linkedin</p>
                        <a href="https://www.linkedin.com/in/ivan-teterin/" className="contacts__link">https://www.linkedin.com/in/ivan-teterin/</a>
                    </div>
                </li>
            </ul>
        </div>
    );
}