import React from 'react';

export function FeedbackElement(props) {
    return (
        <div className='feedback_element' key={props.reporter.name + props.reporter.company}>
            <div className='feedback_element__text_container'>
                <p className='feedback_element__text'>{props.feedback}</p>
            </div>
            <div className='feedback_element__signature_container'>
                <img className='feedback_element__img' alt='Avatar' src={props.reporter.photoUrl} />
                <div className='feedback_element__signature'>
                    <p className='feedback_element__name'>{props.reporter.name}</p>
                    <p className='feedback_element__position'>{props.reporter.position}</p>
                    <p className='feedback_element__company'>{props.reporter.company}</p>
                </div>
            </div>
        </div>
    );
}