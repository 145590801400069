import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ScrollToTheTop() {
    function scroll() {
        window.scrollTo({ top: 0, behavior: 'smooth'})
    }
    return (
        <button className="scroll" onClick={scroll}>
            <FontAwesomeIcon icon={faChevronUp} />
        </button>
    );
}