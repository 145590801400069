import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { addSkill } from "../store/actions";
import { useRef } from "react";

const initialValues = {
    skill: '',
    measure: '',
}

export function SkillsForm() {
    const dispatch = useDispatch();
    const skillInput = useRef();
    const measureInput = useRef();
    const validate = values => {
        const errors = {};
        if (!values.measure) {
            errors.measure = 'Skill range is a required field';            
        }
        else if (values.measure < 10) {
            errors.measure = 'Skill range must be greater than or equal to 10';
        }
        else if (values.measure > 100) {
            errors.measure = 'Skill range must be less than or equal to 100';
        };
        if (!values.skill) {
            errors.skill = 'Skill name is a required field';            
        };
        skillInput.current.className = errors.skill? 'skills__form--invalid_input' : 'skills__form--input';
        measureInput.current.className = errors.measure? 'skills__form--invalid_input' : 'skills__form--input';
        return errors;
      };
    return (
        <>
            <Formik 
            initialValues={initialValues}
            validateOnBlur
            validate={validate}
            onSubmit={(values) => { dispatch(addSkill(values)) }}
            >
                {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                    <div className="skills__form hidden">
                        <div className="skills__form_element">
                            <label className="skills__form--label" htmlFor="skill">Skill name</label>
                            <input
                            ref={skillInput}
                            className="skills__form--input"
                            type={'text'}
                            name="skill"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.skill}
                            placeholder="Enter skill name"/>
                            { touched && errors.skill &&
                            <>
                            <p className="skills__form--error">{errors.skill}</p>
                            </> }
                        </div>
                        <div className="skills__form_element">
                            <label className="skills__form--label" htmlFor="measure">Skill range</label>
                            <input
                            ref={measureInput}
                            className="skills__form--input"
                            type={'number'}
                            name="measure"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.measure}
                            placeholder="Enter skill range"/>
                            { touched && errors.measure &&
                            <>
                            <p className="skills__form--error">{errors.measure}</p>
                            </> }
                        </div>
                        <button 
                        className="skills__form--submit"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!isValid || !dirty}
                        >
                            Add skill
                        </button>
                    </div>
                )}
            </Formik>
        </>
    );
}