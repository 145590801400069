import React from 'react';

export function PortfolioElement(props) {
    return (
        <div className='portfolio__element'>
            <div className="slider__background">
                <img alt="background" src={props.url} className="slider__img" />
            </div>
            <div className='slider'>
                <p className='slider__title'>{props.title}</p>
                <p className='slider__text'>{props.text}</p>
            </div>
        </div>
    );
}