import { Link } from "react-router-dom";
import avatar from '../assets/images/1555491586129.jfif';

export function StartingPage() {
    return(
        <div className="starting_page">
            <img className="starting_page__avatar" src={avatar} alt="User avatar" />
            <h1 className="starting_page__title">Ivan Teterin</h1>
            <h2 className="starting_page__subtitle">Project Manager</h2>
            <div className="staring_page__container">
                <p className="starting_page__motto">Systems Engineering. Quality. Industrial Automation.</p>
            </div>
            <Link to="/main"><button className="starting_page__button">Learn more</button></Link>
        </div>
    )
}