import React from 'react';

export function SkillsElement(props) {
    const skill = props.skill;
    return (
        <div className='skills_element' style={{ width: props.measure + '%'}} key={props.skill}>
            <p className='skills_element__label'>{skill}</p>
        </div>
    );
}
