import { Route, Routes } from 'react-router-dom';
import './assets/styles/App.css';
import { MainPage } from './pages/MainPage';
import { StartingPage } from './pages/StartingPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<StartingPage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </div>
  );
}

export default App;
